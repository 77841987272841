<template>
  <section class="oms">
      <router-view></router-view>
  </section>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import Routes from './POS/routes.js';
// import 'bootstrap';
//import $ from 'jquery';
import vClickOutside from 'v-click-outside';
import VueFlatPickr from 'vue-flatpickr-component';
import VueMeta from 'vue-meta';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VTooltip from 'v-tooltip';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.use(VueMeta)
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(vClickOutside);
Vue.use(VueFlatPickr);
Vue.use(VTooltip);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('v-select', vSelect)

const store=new Vuex.Store({
  state:{
    base_url:'',
    api_url:'',
    api_token:'',
    site:{
      auth_user:{
        name: 'Unknown',
        user_permissions: [],
        permissions: [],
      },
      permission_disable: false
    },
    dropdowns: {},
  },
  mutations: {
    setBaseUrl: function(state, value){
      state.base_url=value;
    },
    setApiUrl: function(state, value){
      state.api_url=value;
    },
    setApiToken: function(state, value){
      state.api_token=value;
    },
    siteInfo: function(state, args){
      state.site[args.key]=args.val;
    },
    setDropdowns: function(state, value){
      state.dropdowns = value;
    },
  }
});

const router=new VueRouter({
  // mode: "history",
  routes: Routes,
});

router.beforeEach((to, from, next)=>{

var api_token=localStorage.getItem('api_token');

if(!api_token){
  api_token = store.state.api_token;;
}

if(to.name=='ResetPassword' || to.name=='ForgotPassword' || to.name=='') next();

else if(to.name!=='Login' && !api_token) {
  sessionStorage.setItem('redirectPath', to.path);
  next({ name: 'Login' })
} else if(to.name=='Login' && api_token) {
  next('/dashboard')
} else if(!to.meta.requiredAuth) {
  next();
}
else next();

});

export default {
  store,
	router,
	name: 'POS',
	props: ['base_url'],
  metaInfo: {
    title: 'POS',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'POS, Point of Sale management system.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0' }
    ]
  },
	created: async function(){

    var ref=this;
    var jq=ref.jq();

		ref.$store.commit('setBaseUrl', ref.base_url);
		// ref.$store.commit('setApiUrl', ref.base_url);

    var api_token = localStorage.getItem('api_token');

    if(!api_token){

      ref.$router.push('/login');

    }

	},
  mounted: function(){

    let api_token=localStorage.getItem('api_token');

    if(api_token){

      this.$store.commit('setApiToken', api_token);
      this.ajax_setup();
    }

    this.getCommonDropdownList()
    this.getAuthUserInfo()
  },
  methods: {
    getCommonDropdownList: async function () {

      let ref = this;
      let url = `${this.base_url}/api/v1/common-ajax/get_common_dropdown_list`;
      let jq = ref.jq();

      try {
          let res = await jq.get(url);
          this.$store.commit('setDropdowns', res.data);
      } catch (error) {
          this.alert(ref.err_msg(error), 'error')
      }
    },
    getAuthUserInfo: async function () {
      
      var ref=this;
      var jq=ref.jq();
        
      try{

        var res = await jq.get(ref.apiUrl('api/v1/admin/ajax/get_auth_user'));

        ref.$store.commit('siteInfo', {
          key: 'auth_user',
          val: res.data.auth_user
        });

      } catch (err) {
        // ref.alert(ref.err_msg(err), 'error');
        localStorage.removeItem('api_token');
        ref.$router.push('/login');
      }
    }
  },
}

</script>


<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~flatpickr/dist/flatpickr.min.css";
@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
@import "../static/frest/assets/vendor/fonts/fontawesome.css";
@import "../static/frest/dist/css/theme-default.css"; 
@import "../static/frest/assets/vendor/css/rtl/core.css";
@import "../static/frest/assets/vendor/css/rtl/theme-default.css";
@import "../static/frest/assets/css/demo.css";
@import "../static/frest/assets/vendor/fonts/boxicons.css";
@import "../static/frest/assets/vendor/fonts/flag-icons.css";
@import "../static/waitMe/waitMe.min.css";
@import "~noty/lib/noty.css";
@import "~noty/lib/themes/sunset.css";
@import "../css/style.css";
</style>
