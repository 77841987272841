export default [
  { 
    path: '/',
    redirect: '/dashboard' 
  },
  {
    path: '/login',
    component: ()=>import('./Login.vue'),
    name: 'Login'
  },
  {
    path: '/logout',
    component: ()=>import('./Logout.vue'),
    name: 'Logout'
  },
  {
    path: '/sign-up',
    component: ()=>import('./SignUp.vue'),
    name: 'SignUp'
  },
  {
    path: '/basic-info',
    component: ()=>import('./BasicInfo.vue'),
    name: 'Register'
  },
  {
    path: '/dashboard',
    component: ()=>import('./Dashboard.vue'),
    name: 'Dashboard',
    meta: { requiredAuth: true }
  },
  {
    path: '/manage',
    component: ()=>import('./Manage.vue'),
    name: 'Manage',
    meta: { requiredAuth: true }
  },
  {
    path: '/manage/departments',
    component: ()=>import('./Departments.vue'),
    name: 'Departments',
    meta: { requiredAuth: true }
  },
  {
    path: '/manage/vat-groups',
    component: ()=>import('./VatGroups.vue'),
    name: 'VatGroups',
    meta: { requiredAuth: true }
  },
  {
    path: '/manage/suppliers',
    component: ()=>import('./Suppliers.vue'),
    name: 'Suppliers',
    meta: { requiredAuth: true }
  },
  {
    path: '/manage/products',
    component: ()=>import('./Products.vue'),
    name: 'Products',
    meta: { requiredAuth: true }
  },
  {
    path: '/manage/users',
    component: ()=>import('./Users.vue'),
    name: 'Users',
    meta: { requiredAuth: true }
  },
  {
    path: '/manage/plu-groups',
    component: ()=>import('./PluGroups.vue'),
    name: 'PluGroups',
    meta: { requiredAuth: true }
  },
]