import Vue from 'vue';
import helperMixin from './lib/helper_mixin.js';
import vueCustomElement from 'vue-custom-element';

// import './css/style.css';

import POS from './components/POS.vue';

Vue.use(vueCustomElement);
Vue.mixin(helperMixin);

Vue.customElement('pos-demo', POS, {});